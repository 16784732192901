<template>
  <div>
    <ListingLayout
      :is-loading="isLoading"
      :meta="meta"
      show-date
      :headers="_headers"
      :items="list"
      :product-options="productOptions"
      search-label="search"
      vertical-scroll
      :filter-names="filterNames"
      @filterOption="onFiltersChange"
    >
      <template v-slot:date_time="{ item }">
        <div>
          {{ item.date_time | formatDate }}
        </div>
      </template>
      <template v-slot:entity_type="{ item }">
        <div class="text-capitalize">
          {{ item.entity_type }}
        </div>
      </template>

      <template #addFilters>
        <v-card>
          <v-select
            v-model="customFilters.terminal_id"
            filled
            dense
            clearable
            :items="terminalList"
            item-value="id"
            item-text="name"
            :label="$t('terminal')"
            @change="onTerminalChange"
          />
        </v-card>
        <v-card>
          <v-select
            v-model="customFilters.dispenser_id"
            filled
            dense
            clearable
            :items="dispenserList"
            item-value="id"
            item-text="dispenser_identifier"
            :label="$t('dispenser')"
            @change="fetchData"
          />
        </v-card>
      </template>
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { debounce } from "lodash";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      locationModal: false,
      productOptions: [
        { id: "oem", value: "OEM" },
        { id: "fleet", value: "Fleet" },
      ],
      operational_status: null,
      isMounted: false,
      filters: {},
      subFilters: {},
      //
      customFilters: {},
      statusOptions: [
        {
          id: true,
          name: "active",
        },
        {
          id: false,
          name: "inactive",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "fuelTanks/getSessionLoading",
      meta: "fuelTanks/getSessionMeta",
      list: "fuelTanks/getSessionList",
      terminalList: "terminal/getTerminalDropDown",
      dispenserList: "fuelDispenser/getList",
    }),
    filterNames() {
      return [];
    },
    _headers() {
      const headers = [
        {
          text: this.$t("session_id"),
          align: "left",
          sortable: true,
          value: "session_id",
        },
        {
          text: this.$t("dispenser_identifier"),
          sortable: false,
          value: "dispenser_identifier_cid",
        },
        {
          text: this.$t("imei"),
          align: "left",
          sortable: false,
          value: "imei",
        },
        {
          text: this.$t("date_time"),
          align: "left",
          sortable: false,
          value: "date_time",
        },
        {
          text: this.$t("nozzle_number"),
          align: "center",
          sortable: false,
          value: "nozzle_number",
        },
        {
          text: this.$options.filters.currencyFormat(
            this.$t("fuel_cost"),
            this.authUser
          ),
          align: "center",
          sortable: false,
          value: "fuel_cost",
        },
        {
          text: this.$t("durationMin"),
          align: "center",
          sortable: false,
          value: "duration",
        },
        {
          text: this.$t("quantity"),
          align: "center",
          sortable: false,
          value: "qty",
        },
      ];
      // computed headers for showing columns to role based
      return headers.filter(
        (header) =>
          header.showAdmin === undefined ||
          (header.showAdmin != undefined &&
            header.showAdmin === this.authUser.isAdmin)
      );
    },
  },
  watch: {
    options(val) {
      if (this.authUser.isAdmin) {
        if (!val.tenant_id) {
          this.options.tenant_id = val.fleetId;
        }
        if (this.isMounted) {
          this.customFilters = {};
        }
        this.isMounted = true;
        if (this.options.tenant_id) {
          const params = { tenant_id: this.options.tenant_id };
          this.$store.dispatch("terminal/dropDownList", params);
        } else {
          this.$store.commit("terminal/SET_LIST_DROPDOWN", []);
        }
      }
      this.fetchData();
    },
  },
  async mounted() {
    this.$store.dispatch("fuelTanks/setSessionLoadingValue", true);
    if (!this.authUser.isAdmin) {
      const params = { tenant_id: this.authUser.tenant_id };
      await this.$store.dispatch("terminal/dropDownList", params);
    }

    // setTimeout(() => {
    if (this.$route?.query?.terminal_id) {
      this.customFilters.terminal_id = parseInt(this.$route.query.terminal_id);
      this.onTerminalChange(this.customFilters.terminal_id);
    }
    if (this.$route?.query?.dispenser_id) {
      this.customFilters.dispenser_id = parseInt(
        this.$route.query.dispenser_id
      );
    }
    // }, 1500);
  },

  beforeDestroy() {
    const paramsset = { ...this.options, ...this.customFilters };
    const params = {
      name: "vehicle_fuel_sessions",
      filters: paramsset,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    fetchData: debounce(async function () {
      const params = { ...this.options, ...this.customFilters };
      await this.$store.dispatch("fuelTanks/sessionsList", params).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    }, 1500),
    onTerminalChange(v) {
      delete this.customFilters.fuel_tank_id;
      delete this.customFilters.dispenser_id;

      if (v) {
        const params = { terminal_id: v };
        this.$store.dispatch("fuelDispenser/list", params);
      } else {
        this.$store.commit("fuelDispenser/SET_LIST", []);
      }
      this.fetchData();
    },
  },
};
</script>
<style lang="sass" scoped>
.map-button
  height: 41px !important
  margin-right: 5px

.btn-disabled
  pointer-events: none !important
  background-color: #8f8f8f !important

:deep .v-autocomplete.v-text-field--enclosed:not(.v-text-field--solo):not(.v-text-field--single-line):not(.v-text-field--outlined).v-input--dense .v-select__slot > input
  font-size: 0.875rem !important
</style>
